.huik-button.external-link {
  border-radius: 4px;
  padding-left: 0;
}
.huik-button.external-link.twitter {
  color: #00acee;
}
.huik-button.external-link.twitter:hover {
  color: #00acee;
}
.huik-button.external-link.linkedin {
  color: #0e76a8;
}
.huik-button.external-link.linkedin:hover {
  color: #0e76a8;
}
.huik-button.external-link.youtube {
  color: #c4302b;
}
.huik-button.external-link.youtube:hover {
  color: #c4302b;
}
