.youtube-card {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 260px;
}
.youtube-card .youtube-card-image-container {
  position: relative;
  display: block;
}
.youtube-card .youtube-card-image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  height: 140px;
}
.youtube-card .youtube-card-duration {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 8px 4px;
  padding: 0 4px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #000;
  color: #fff;
}
.youtube-card .youtube-card-play-img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.youtube-card .youtube-card-text {
  padding: 15px 5px;
  word-wrap: break-word;
  color: #fff;
}
