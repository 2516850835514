.newsletter {
  margin: 0 32px;
  padding: 48px 24px;
  text-align: center;
  color: #fff;
  background: #2b1d46;
}
.newsletter p {
  max-width: 420px;
  margin: 15px auto;
}
.newsletter .newsletter-top-image {
  display: none;
  margin-bottom: 10px;
}
.newsletter .newsletter-image-container {
  background-image: url("../../../assets/img/newsletter-full.svg");
  background-repeat: no-repeat;
  background-position: calc(50% + 60px) center;
}
@media (max-width: 960px) {
  .newsletter {
    margin: 0;
  }
  .newsletter .newsletter-top-image {
    display: block;
  }
  .newsletter .newsletter-image-container {
    background: none;
  }
}
