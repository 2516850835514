.huik-input-message {
  display: flex;
  align-items: center;
  color: #2b1d46;
  min-height: 32px;
}
.huik-input-message .icon {
  color: #2b1d46;
}
.huik-input-message.error {
  color: #ff3c48;
}
.huik-input-message.error .icon {
  color: #ff3c48;
}
.huik-input-message.warning {
  color: #ffa500;
}
.huik-input-message.warning .icon {
  color: #ffa500;
}
.huik-input-message.info {
  color: #add8e6;
}
.huik-input-message.info .icon {
  color: #add8e6;
}
.huik-input-message.success {
  color: #90ee90;
}
.huik-input-message.success .icon {
  color: #90ee90;
}
