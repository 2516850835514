.huik-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.huik-icon {
  display: block;
  font-family: 'HEVA' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
  font-size: 2rem;
  color: inherit;
}
.huik-icon.primary {
  color: #00f;
}
.huik-icon.secondary {
  color: #ffcb05;
}
.huik-icon.primary-contrast {
  color: rgba(0,0,255,0.1);
}
.huik-icon.secondary-contrast {
  color: $huikColorSecond;
}
.huik-icon.secondary {
  color: #ffcb05;
}
.huik-icon.success {
  color: #90ee90;
}
.huik-icon.info {
  color: #add8e6;
}
.huik-icon.warning {
  color: #ffa500;
}
.huik-icon.error {
  color: #ff3c48;
}
