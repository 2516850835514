.huik-input-container {
  min-width: 144px;
  margin: 8px 8px;
}
.huik-input-container .huik-input-content {
  width: 100%;
}
.huik-input-container input,
.huik-input-container textarea,
.huik-input-container .input-file-element {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.43;
  padding: 9.04px 12px;
}
.huik-input-container.small {
  margin: 4px 4px;
}
.huik-input-container.small input,
.huik-input-container.small textarea,
.huik-input-container.small .input-file-element {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 12px;
  line-height: 0.75rem;
  padding: 4px 8px;
}
.huik-input-container.large {
  margin: 8px 8px;
}
.huik-input-container.large input,
.huik-input-container.large textarea,
.huik-input-container.large .input-file-element {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.43;
  padding: 12px 16px;
}
.huik-input-container input,
.huik-input-container textarea {
  border-radius: 3px;
  width: 100%;
}
.huik-input-container input.primary,
.huik-input-container textarea.primary {
  border: solid 1px transparent;
  color: #fff;
  background-color: #00f;
  transition: background ease 150ms;
}
.huik-input-container input.primary.hover,
.huik-input-container textarea.primary.hover,
.huik-input-container input.primary:hover,
.huik-input-container textarea.primary:hover {
  color: #fff;
  border-color: transparent;
  background-color: #00f;
}
.huik-input-container input.primary.hover + .huik-icon-container,
.huik-input-container textarea.primary.hover + .huik-icon-container,
.huik-input-container input.primary:hover + .huik-icon-container,
.huik-input-container textarea.primary:hover + .huik-icon-container {
  color: #fff;
  border-color: transparent;
  background-color: #00f;
}
.huik-input-container input.primary.active,
.huik-input-container textarea.primary.active,
.huik-input-container input.primary:active,
.huik-input-container textarea.primary:active {
  color: #fff;
  border-color: transparent;
  background-color: #00f;
}
.huik-input-container input.primary.active + .huik-icon-container,
.huik-input-container textarea.primary.active + .huik-icon-container,
.huik-input-container input.primary:active + .huik-icon-container,
.huik-input-container textarea.primary:active + .huik-icon-container {
  color: #fff;
  border-color: transparent;
  background-color: #00f;
}
.huik-input-container input.primary.disabled,
.huik-input-container textarea.primary.disabled,
.huik-input-container input.primary[disabled],
.huik-input-container textarea.primary[disabled] {
  cursor: default;
  color: #c9c8d6;
  border-color: rgba(201,200,214,0.5);
  background-color: rgba(201,200,214,0.5);
}
.huik-input-container input.primary.disabled + .huik-icon-container,
.huik-input-container textarea.primary.disabled + .huik-icon-container,
.huik-input-container input.primary[disabled] + .huik-icon-container,
.huik-input-container textarea.primary[disabled] + .huik-icon-container {
  color: #c9c8d6;
  border-color: rgba(201,200,214,0.5);
  background-color: rgba(201,200,214,0.5);
}
.huik-input-container input.primary:focus,
.huik-input-container textarea.primary:focus {
  color: #fff;
  border-color: transparent;
  background-color: #00f;
}
.huik-input-container input.secondary,
.huik-input-container textarea.secondary {
  border: solid 1px rgba(108,93,137,0.2);
  color: #2b1d46;
  background-color: #ffcb05;
  transition: background ease 150ms;
}
.huik-input-container input.secondary.hover,
.huik-input-container textarea.secondary.hover,
.huik-input-container input.secondary:hover,
.huik-input-container textarea.secondary:hover {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #ffcb05;
}
.huik-input-container input.secondary.hover + .huik-icon-container,
.huik-input-container textarea.secondary.hover + .huik-icon-container,
.huik-input-container input.secondary:hover + .huik-icon-container,
.huik-input-container textarea.secondary:hover + .huik-icon-container {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #ffcb05;
}
.huik-input-container input.secondary.active,
.huik-input-container textarea.secondary.active,
.huik-input-container input.secondary:active,
.huik-input-container textarea.secondary:active {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #ffcb05;
}
.huik-input-container input.secondary.active + .huik-icon-container,
.huik-input-container textarea.secondary.active + .huik-icon-container,
.huik-input-container input.secondary:active + .huik-icon-container,
.huik-input-container textarea.secondary:active + .huik-icon-container {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #ffcb05;
}
.huik-input-container input.secondary.disabled,
.huik-input-container textarea.secondary.disabled,
.huik-input-container input.secondary[disabled],
.huik-input-container textarea.secondary[disabled] {
  cursor: default;
  color: rgba(43,29,70,0.5);
  border-color: rgba(108,93,137,0.2);
  background-color: rgba(255,255,255,0.6);
}
.huik-input-container input.secondary.disabled + .huik-icon-container,
.huik-input-container textarea.secondary.disabled + .huik-icon-container,
.huik-input-container input.secondary[disabled] + .huik-icon-container,
.huik-input-container textarea.secondary[disabled] + .huik-icon-container {
  color: rgba(43,29,70,0.5);
  border-color: rgba(108,93,137,0.2);
  background-color: rgba(255,255,255,0.6);
}
.huik-input-container input.secondary:focus,
.huik-input-container textarea.secondary:focus {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #ffcb05;
}
.huik-input-container input.primary-contrast,
.huik-input-container textarea.primary-contrast {
  border: solid 1px rgba(108,93,137,0.2);
  color: #2b1d46;
  background-color: #fff;
  transition: background ease 150ms;
}
.huik-input-container input.primary-contrast.hover,
.huik-input-container textarea.primary-contrast.hover,
.huik-input-container input.primary-contrast:hover,
.huik-input-container textarea.primary-contrast:hover {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #fff;
}
.huik-input-container input.primary-contrast.hover + .huik-icon-container,
.huik-input-container textarea.primary-contrast.hover + .huik-icon-container,
.huik-input-container input.primary-contrast:hover + .huik-icon-container,
.huik-input-container textarea.primary-contrast:hover + .huik-icon-container {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #fff;
}
.huik-input-container input.primary-contrast.active,
.huik-input-container textarea.primary-contrast.active,
.huik-input-container input.primary-contrast:active,
.huik-input-container textarea.primary-contrast:active {
  color: #2b1d46;
  border-color: #2bbdc8;
  background-color: #fff;
}
.huik-input-container input.primary-contrast.active + .huik-icon-container,
.huik-input-container textarea.primary-contrast.active + .huik-icon-container,
.huik-input-container input.primary-contrast:active + .huik-icon-container,
.huik-input-container textarea.primary-contrast:active + .huik-icon-container {
  color: #2b1d46;
  border-color: #2bbdc8;
  background-color: #fff;
}
.huik-input-container input.primary-contrast.disabled,
.huik-input-container textarea.primary-contrast.disabled,
.huik-input-container input.primary-contrast[disabled],
.huik-input-container textarea.primary-contrast[disabled] {
  cursor: default;
  color: #c9c8d6;
  border-color: rgba(74,37,114,0.2);
  background-color: rgba(201,200,214,0.5);
}
.huik-input-container input.primary-contrast.disabled + .huik-icon-container,
.huik-input-container textarea.primary-contrast.disabled + .huik-icon-container,
.huik-input-container input.primary-contrast[disabled] + .huik-icon-container,
.huik-input-container textarea.primary-contrast[disabled] + .huik-icon-container {
  color: #c9c8d6;
  border-color: rgba(74,37,114,0.2);
  background-color: rgba(201,200,214,0.5);
}
.huik-input-container input.primary-contrast:focus,
.huik-input-container textarea.primary-contrast:focus {
  color: #2b1d46;
  border-color: #2bbdc8;
  background-color: #fff;
}
.huik-input-container input.secondary-contrast,
.huik-input-container textarea.secondary-contrast {
  border: solid 1px #999;
  color: #2b1d46;
  background-color: rgba(255,203,5,0.1);
  transition: background ease 150ms;
}
.huik-input-container input.secondary-contrast.hover,
.huik-input-container textarea.secondary-contrast.hover,
.huik-input-container input.secondary-contrast:hover,
.huik-input-container textarea.secondary-contrast:hover {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(255,203,5,0.1);
}
.huik-input-container input.secondary-contrast.hover + .huik-icon-container,
.huik-input-container textarea.secondary-contrast.hover + .huik-icon-container,
.huik-input-container input.secondary-contrast:hover + .huik-icon-container,
.huik-input-container textarea.secondary-contrast:hover + .huik-icon-container {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(255,203,5,0.1);
}
.huik-input-container input.secondary-contrast.active,
.huik-input-container textarea.secondary-contrast.active,
.huik-input-container input.secondary-contrast:active,
.huik-input-container textarea.secondary-contrast:active {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(255,203,5,0.1);
}
.huik-input-container input.secondary-contrast.active + .huik-icon-container,
.huik-input-container textarea.secondary-contrast.active + .huik-icon-container,
.huik-input-container input.secondary-contrast:active + .huik-icon-container,
.huik-input-container textarea.secondary-contrast:active + .huik-icon-container {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(255,203,5,0.1);
}
.huik-input-container input.secondary-contrast.disabled,
.huik-input-container textarea.secondary-contrast.disabled,
.huik-input-container input.secondary-contrast[disabled],
.huik-input-container textarea.secondary-contrast[disabled] {
  cursor: default;
  color: rgba(43,29,70,0.5);
  border-color: #999;
  background-color: rgba(255,255,255,0.6);
}
.huik-input-container input.secondary-contrast.disabled + .huik-icon-container,
.huik-input-container textarea.secondary-contrast.disabled + .huik-icon-container,
.huik-input-container input.secondary-contrast[disabled] + .huik-icon-container,
.huik-input-container textarea.secondary-contrast[disabled] + .huik-icon-container {
  color: rgba(43,29,70,0.5);
  border-color: #999;
  background-color: rgba(255,255,255,0.6);
}
.huik-input-container input.secondary-contrast:focus,
.huik-input-container textarea.secondary-contrast:focus {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(255,203,5,0.1);
}
.huik-input-container input.success,
.huik-input-container textarea.success {
  border-color: #90ee90;
}
.huik-input-container input.success:focus,
.huik-input-container textarea.success:focus,
.huik-input-container input.success:hover,
.huik-input-container textarea.success:hover {
  border-color: #90ee90;
}
.huik-input-container input.warning,
.huik-input-container textarea.warning {
  border-color: #ffa500;
}
.huik-input-container input.warning:focus,
.huik-input-container textarea.warning:focus,
.huik-input-container input.warning:hover,
.huik-input-container textarea.warning:hover {
  border-color: #ffa500;
}
.huik-input-container input.error,
.huik-input-container textarea.error {
  border-color: #ff3c48;
}
.huik-input-container input.error:focus,
.huik-input-container textarea.error:focus,
.huik-input-container input.error:hover,
.huik-input-container textarea.error:hover {
  border-color: #ff3c48;
}
.huik-input-container input.info,
.huik-input-container textarea.info {
  border-color: #add8e6;
}
.huik-input-container input.info:focus,
.huik-input-container textarea.info:focus,
.huik-input-container input.info:hover,
.huik-input-container textarea.info:hover {
  border-color: #add8e6;
}
.huik-input-container.no-margin input,
.huik-input-container.no-margin textarea {
  margin-left: 0;
  margin-right: 0;
}
.huik-input-container .huik-input-with-icon {
  position: relative;
}
.huik-input-container .huik-input-with-icon input,
.huik-input-container .huik-input-with-icon textarea {
  padding-right: 44px;
}
.huik-input-container .huik-input-with-icon .huik-icon-container {
  pointer-events: none;
  position: absolute;
  width: 32px;
  right: 1px;
  top: 1px;
  bottom: 1px;
}
.huik-input-container.small .huik-input-with-icon input,
.huik-input-container.small .huik-input-with-icon textarea {
  padding-right: 40px;
}
.huik-input-container.large .huik-input-with-icon input,
.huik-input-container.large .huik-input-with-icon textarea {
  padding-right: 48px;
}
.huik-input-container.huik-input-file .huik-input-content,
.huik-input-container.huik-input-file input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.huik-input-container.huik-input-file label {
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
.huik-input-container.huik-input-file .input-file-element.huik-button {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  border-radius: 3px;
  padding-left: 32px;
}
.huik-input-container.huik-input-file .input-file-element.huik-button .huik-icon-container {
  pointer-events: none;
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
}
.huik-input-container.huik-input-file .input-file-element.file-name {
  display: inline-block;
  flex: 1;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  border: solid 1px rgba(108,93,137,0.2);
}
.huik-input-container.huik-input-file .input-file-element.file-name.no-file {
  color: rgba(74,37,114,0.2);
}
.huik-input-container.huik-input-file .input-file-element.file-name.error {
  border-color: #ff3c48;
}
.huik-input-container.small.huik-input-file .input-file-element.huik-button {
  padding-left: 32px;
}
.huik-input-container.small.huik-input-file .input-file-element.huik-button .huik-icon {
  font-size: 28px;
  font-size: 1.75rem;
}
.huik-input-container.large.huik-input-file .input-file-element.huik-button {
  padding-left: 32px;
}
