footer {
  position: relative;
  z-index: 3;
}
footer:after {
  display: block;
  content: '';
  height: 32px;
  background-color: #fff;
}
footer.no-content:after {
  display: none;
}
footer .bottom-footer {
  position: relative;
}
footer .bottom-footer .bottom-footer-inner {
  position: relative;
  z-index: 2;
  background: linear-gradient(60deg, rgba(255,107,137,0.2), rgba(255,102,96,0)), linear-gradient(to bottom, #2b1d46, #2b1d46), linear-gradient(to right, rgba(43,29,70,0.8), rgba(43,29,70,0.8));
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .bottom-footer .bottom-footer-inner .logo-heva {
  display: block;
  margin: 0 16px;
}
footer .bottom-footer .bottom-footer-inner .logo-docaposte {
  display: block;
}
footer .bottom-footer .bottom-footer-inner .link-docaposte {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
footer .bottom-footer .bottom-footer-inner .heva-legacy,
footer .bottom-footer .bottom-footer-inner .policy-link {
  font-weight: normal;
  color: #fff;
}
footer .bottom-footer .bottom-footer-inner .policy-link {
  opacity: 0.6;
}
footer .bottom-footer .bottom-footer-inner .policy-link:hover {
  opacity: 0.8;
}
footer .bottom-footer .bottom-footer-inner .copyright {
  color: #fff;
  margin-left: auto;
}
footer .bottom-footer:before {
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to right, #fff, #fff 0, transparent 0, transparent), linear-gradient(to left, #fff, #fff 0, transparent 0, transparent), linear-gradient(243deg, rgba(255,107,137,0.1), rgba(43,29,70,0.69)), linear-gradient(to bottom, rgba(43,29,70,0.8), rgba(43,29,70,0.8));
}
@media (max-width: 960px) {
  footer:after {
    display: none;
  }
  footer .bottom-footer .bottom-footer-inner {
    flex-direction: column;
    text-align: center;
  }
  footer .bottom-footer .bottom-footer-inner .link-docaposte {
    margin-left: 0;
  }
}
