.contact {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 32px 0;
  background: #fff;
  color: #fff;
}
.contact:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to right, #fff, #fff 0, transparent 0, transparent), linear-gradient(to left, #fff, #fff 0, transparent 0, transparent), linear-gradient(243deg, rgba(255,107,137,0.1), rgba(43,29,70,0.69)), linear-gradient(to bottom, rgba(43,29,70,0.8), rgba(43,29,70,0.8));
}
.contact .contact-infos a {
  color: inherit;
}
.contact .contact-infos a:hover {
  color: rgba(255,255,255,0.8);
}
.contact > .contact-col {
  position: relative;
  width: 332px;
  max-width: 332px;
  margin-left: 32px;
  margin-right: 32px;
}
.contact > .contact-col h3 {
  padding: 16px 0;
  text-align: center;
}
@media (max-width: 960px) {
  .contact {
    flex-flow: column;
    align-items: center;
  }
  .contact:before {
    background-image: linear-gradient(to right, #fff, #fff 0, transparent 0, transparent), linear-gradient(to left, #fff, #fff 0, transparent 0, transparent), linear-gradient(243deg, rgba(255,107,137,0.1), rgba(43,29,70,0.69)), linear-gradient(to bottom, rgba(43,29,70,0.8), rgba(43,29,70,0.8));
  }
  .contact > .contact-col {
    width: 100%;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  .contact > .contact-col:not(:last-child) {
    margin-bottom: 32px;
  }
}
