.modal {
  position: relative;
  background: #2b1d46;
  max-width: 530px;
  border-radius: 20px;
  box-shadow: 2px 2px 34px 0 rgba(7,7,7,0.5);
  padding: 15px;
  z-index: 5;
  color: #fff;
}
.modal:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  opacity: 0.4;
  background: radial-gradient(circle, #dd255f 0%, #dd255f 16.09%, rgba(240,76,152,0) 100%);
  pointer-events: none;
  z-index: -1;
}
.modal .form-container .huik-input-footer {
  min-height: 0;
}
.modal h1 {
  line-height: 45px;
  line-height: 2.8125rem;
}
.modal .modal-content {
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 30px;
}
.modal .close-modal {
  cursor: pointer;
  color: #fff;
  margin: 20px 40px;
  position: absolute;
  right: 0;
}
@media (max-width: 600px) {
  .modal {
    max-width: calc(100% - 30px);
  }
  .modal .modal-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
