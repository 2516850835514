.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: #fff;
}
.header .top-nav .site-heva-heor-nav {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .top-nav .site-heva-heor-nav .huik-link {
  margin: 0 21px;
  color: #2b1d46;
}
.header .top-nav .site-heva-heor-nav .huik-link.active {
  color: #ffcb05;
}
.header .bottom-nav {
  transition: height ease 250ms;
  height: 144px;
  display: flex;
}
.header .bottom-nav .constant-header {
  align-self: center;
  display: flex;
  align-items: center;
}
.header .bottom-nav .constant-header .logo {
  display: block;
  margin: 0 16px;
}
.header .bottom-nav .constant-header .mobile-actions {
  display: none;
}
.header .bottom-nav .navigation {
  flex: 1;
  display: flex;
}
.header .bottom-nav .navigation .internal-nav {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .bottom-nav .navigation .nav-link {
  display: block;
  margin: 0 26px;
  text-transform: uppercase;
  text-align: center;
}
.header .bottom-nav .navigation .nav-link.active {
  color: #ffcb05;
  cursor: default;
}
.header .bottom-nav .language-switch {
  display: flex;
  margin-left: auto;
  padding: 0 26px;
  background-color: #3e305b;
}
.header .bottom-nav .language-switch .language-switch-link {
  margin: 0 14px;
  text-transform: uppercase;
}
.header .bottom-nav .language-switch .language-switch-link.active {
  color: #ffcb05;
  cursor: default;
}
.sticky-header-spacer {
  height: 176px;
  transition: height ease 250ms;
}
body.scrolled .header .bottom-nav {
  background-color: #2b1d46;
  height: 64px;
}
body.scrolled .sticky-header-spacer {
  height: 96px;
}
body:not(.home) .header .bottom-nav,
body:not(.teams) .header .bottom-nav,
body:not(.prestations) .header .bottom-nav,
body:not(.solutions) .header .bottom-nav {
  background-color: #2b1d46;
}
@media (max-width: 1200px) {
  .header .bottom-nav .navigation .nav-link {
    margin: 0 16px;
  }
}
@media (max-width: 1060px) {
  .header .bottom-nav .navigation .nav-link {
    margin: 0 8px;
  }
}
@media (max-width: 960px) {
  .header {
    position: relative;
  }
  .header .bottom-nav .constant-header {
    flex: 1;
    position: relative;
    display: flex;
  }
  .header .bottom-nav .constant-header .mobile-actions,
  .header .bottom-nav .constant-header:before {
    content: '';
    display: block;
    flex: 1;
  }
  .header .bottom-nav .constant-header .mobile-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header .bottom-nav .constant-header .mobile-actions .mobile-menu-btn {
    cursor: pointer;
    font-size: 44px;
    font-size: 2.75rem;
    color: #fff;
  }
  .header .bottom-nav .navigation {
    position: fixed;
    overflow: auto;
    background-color: #2b1d46;
    top: 176px;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    flex-direction: column;
  }
  .header .bottom-nav .navigation .internal-nav {
    flex: none;
    flex-direction: column;
  }
  .header .bottom-nav .navigation .language-switch {
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  .header .bottom-nav .navigation .huik-link {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 0.67;
    padding: 42px 0;
  }
  .header .bottom-nav .navigation.open {
    display: flex;
  }
  .sticky-header-spacer {
    display: none;
  }
  body.scrolled .header .bottom-nav {
    height: 144px;
  }
}
