.huik-link {
  display: inline-flex;
  align-items: center;
  color: #fff;
}
.huik-link.small {
  font-size: 12px;
  font-size: 0.75rem;
}
.huik-link:hover {
  color: rgba(255,255,255,0.8);
}
.huik-link.primary {
  color: #00f;
}
.huik-link.primary:hover {
  color: #2221d3;
}
.huik-link.primary-contrast {
  color: #fff;
}
.huik-link.primary-contrast:hover {
  color: rgba(255,255,255,0.8);
}
.huik-link.secondary {
  color: #ffcb05;
}
.huik-link.secondary:hover {
  color: rgba(255,255,255,0.8);
}
.huik-link.secondary-contrast {
  color: #fff;
}
.huik-link.secondary-contrast:hover {
  color: rgba(255,255,255,0.8);
}
.huik-link.noStyle {
  color: inherit;
}
.huik-link.noStyle:hover {
  color: inherit;
}
