.huik-button {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 9999px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.43;
  font-weight: 600;
  margin: 8px 8px;
  padding: 9.04px 12px;
}
.huik-button.small {
  border-radius: 3px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 12px;
  line-height: 0.75rem;
  margin: 4px 4px;
  padding: 4px 8px;
}
.huik-button.large {
  border-radius: 9999px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.43;
  margin: 8px 8px;
  padding: 12px 16px;
}
.huik-button.primary {
  border: solid 1px transparent;
  color: #fff;
  background-color: #00f;
  transition: background ease 150ms;
  font-weight: 600;
}
.huik-button.primary.hover,
.huik-button.primary:hover {
  color: #fff;
  border-color: transparent;
  background-color: #2221d3;
}
.huik-button.primary.hover + .huik-icon-container,
.huik-button.primary:hover + .huik-icon-container {
  color: #fff;
  border-color: transparent;
  background-color: #2221d3;
}
.huik-button.primary.active,
.huik-button.primary:active {
  color: #fff;
  border-color: transparent;
  background-color: #4343a9;
}
.huik-button.primary.active + .huik-icon-container,
.huik-button.primary:active + .huik-icon-container {
  color: #fff;
  border-color: transparent;
  background-color: #4343a9;
}
.huik-button.primary.disabled,
.huik-button.primary[disabled] {
  cursor: default;
  color: #c9c8d6;
  border-color: rgba(201,200,214,0.5);
  background-color: rgba(201,200,214,0.5);
}
.huik-button.primary.disabled + .huik-icon-container,
.huik-button.primary[disabled] + .huik-icon-container {
  color: #c9c8d6;
  border-color: rgba(201,200,214,0.5);
  background-color: rgba(201,200,214,0.5);
}
.huik-button.secondary {
  border: solid 1px rgba(108,93,137,0.2);
  color: #2b1d46;
  background-color: #ffcb05;
  transition: background ease 150ms;
  font-weight: 600;
}
.huik-button.secondary.hover,
.huik-button.secondary:hover {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #eab900;
}
.huik-button.secondary.hover + .huik-icon-container,
.huik-button.secondary:hover + .huik-icon-container {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #eab900;
}
.huik-button.secondary.active,
.huik-button.secondary:active {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #d0a500;
}
.huik-button.secondary.active + .huik-icon-container,
.huik-button.secondary:active + .huik-icon-container {
  color: #2b1d46;
  border-color: rgba(108,93,137,0.2);
  background-color: #d0a500;
}
.huik-button.secondary.disabled,
.huik-button.secondary[disabled] {
  cursor: default;
  color: rgba(43,29,70,0.5);
  border-color: rgba(108,93,137,0.2);
  background-color: rgba(255,255,255,0.6);
}
.huik-button.secondary.disabled + .huik-icon-container,
.huik-button.secondary[disabled] + .huik-icon-container {
  color: rgba(43,29,70,0.5);
  border-color: rgba(108,93,137,0.2);
  background-color: rgba(255,255,255,0.6);
}
.huik-button.primary-contrast {
  border: solid 1px rgba(74,37,114,0.2);
  color: #00f;
  background-color: #fff;
  transition: background ease 150ms;
}
.huik-button.primary-contrast.hover,
.huik-button.primary-contrast:hover {
  color: #00f;
  border-color: rgba(74,37,114,0.2);
  background-color: #f5f3f7;
}
.huik-button.primary-contrast.hover + .huik-icon-container,
.huik-button.primary-contrast:hover + .huik-icon-container {
  color: #00f;
  border-color: rgba(74,37,114,0.2);
  background-color: #f5f3f7;
}
.huik-button.primary-contrast.active,
.huik-button.primary-contrast:active {
  color: #00f;
  border-color: rgba(74,37,114,0.2);
  background-color: #ede9f1;
}
.huik-button.primary-contrast.active + .huik-icon-container,
.huik-button.primary-contrast:active + .huik-icon-container {
  color: #00f;
  border-color: rgba(74,37,114,0.2);
  background-color: #ede9f1;
}
.huik-button.primary-contrast.disabled,
.huik-button.primary-contrast[disabled] {
  cursor: default;
  color: #c9c8d6;
  border-color: rgba(74,37,114,0.2);
  background-color: rgba(201,200,214,0.5);
}
.huik-button.primary-contrast.disabled + .huik-icon-container,
.huik-button.primary-contrast[disabled] + .huik-icon-container {
  color: #c9c8d6;
  border-color: rgba(74,37,114,0.2);
  background-color: rgba(201,200,214,0.5);
}
.huik-button.secondary-contrast {
  border: solid 1px #999;
  color: #2b1d46;
  background-color: rgba(255,203,5,0.1);
  transition: background ease 150ms;
}
.huik-button.secondary-contrast.hover,
.huik-button.secondary-contrast:hover {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(234,185,0,0.1);
}
.huik-button.secondary-contrast.hover + .huik-icon-container,
.huik-button.secondary-contrast:hover + .huik-icon-container {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(234,185,0,0.1);
}
.huik-button.secondary-contrast.active,
.huik-button.secondary-contrast:active {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(208,165,0,0.1);
}
.huik-button.secondary-contrast.active + .huik-icon-container,
.huik-button.secondary-contrast:active + .huik-icon-container {
  color: #2b1d46;
  border-color: #999;
  background-color: rgba(208,165,0,0.1);
}
.huik-button.secondary-contrast.disabled,
.huik-button.secondary-contrast[disabled] {
  cursor: default;
  color: rgba(43,29,70,0.5);
  border-color: #999;
  background-color: rgba(255,255,255,0.6);
}
.huik-button.secondary-contrast.disabled + .huik-icon-container,
.huik-button.secondary-contrast[disabled] + .huik-icon-container {
  color: rgba(43,29,70,0.5);
  border-color: #999;
  background-color: rgba(255,255,255,0.6);
}
.huik-button:not(.link) .huik-icon {
  color: inherit;
  margin: -8px 0;
}
.huik-button.centered {
  justify-content: center;
}
.huik-button.rounded-icon {
  min-width: auto;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1;
  padding: 3.96px;
}
.huik-button.rounded-icon .huik-icon {
  margin: 0;
}
.huik-button.link {
  margin: 0;
  padding: 0;
  border-radius: 0;
  line-height: 32px;
  line-height: 2rem;
  justify-content: flex-start;
}
.huik-button.no-margin {
  margin: 0;
}
@media (max-width: 960px) {
  .huik-button.small {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.43;
    margin: 4px 8px;
    padding: 9.04px 12px;
  }
}
