.twitter-card {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 260px;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.08);
  background-color: #fff;
}
.twitter-card .twitter-card-img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-family: 'object-fit: cover;';
  height: 140px;
}
.twitter-card .twitter-card-text {
  padding: 15px;
  word-wrap: break-word;
}
.twitter-card .twitter-card-text a {
  color: #00f;
}
.twitter-card .twitter-card-text a:hover {
  color: #2221d3;
}
