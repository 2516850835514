.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(148,145,173,0.8);
  z-index: 500;
}
