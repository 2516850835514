.carousel-container .carousel-wrapper {
  background-color: #f4f2f7;
  padding-top: 32px;
  padding-bottom: 32px;
}
.carousel-container.youtube-video-carousel .carousel-wrapper {
  background: linear-gradient(225deg, rgba(255,107,137,0.1) 0%, rgba(43,29,70,0.69) 100%), #2b1d46;
}
.carousel-container.youtube-video-carousel .carousel-wrapper .carousel-title {
  color: #fff;
}
.carousel-container.youtube-video-carousel .carousel-wrapper .carousel-content .slick-arrow {
  top: 27%;
}
.carousel-container .carousel {
  max-width: 882px;
  margin-left: auto;
  margin-right: auto;
}
.carousel-container .carousel-title {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
}
.carousel-container .carousel-title h3 {
  width: 33%;
  margin-left: 33%;
  text-align: center;
}
.carousel-container .carousel-title .external-links {
  text-align: right;
  width: 34%;
}
.carousel-container .carousel-content {
  min-height: 300px;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  min-height: 1px;
  padding: 16px;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-slide > div,
.slick-slide .carousel-card {
  height: 100%;
}
.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 4px;
}
.slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
  background-color: #ffcb05;
}
.slick-dots li button {
  border: 0;
  display: block;
  width: 6px;
  height: 6px;
  outline: none;
  line-height: 0px;
  line-height: 0;
  font-size: 0px;
  font-size: 0;
  color: transparent;
  background-color: #cfceda;
  border-radius: 100%;
  transition: all ease 150ms;
  cursor: pointer;
}
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  line-height: 0px;
  line-height: 0;
  font-size: 0px;
  font-size: 0;
  cursor: pointer;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 4px;
  border: 1px solid rgba(74,37,114,0.2);
  outline: none;
  background-color: #fff;
  border-radius: 9999px;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  color: transparent;
  background-color: #f5f3f7;
}
.slick-prev.active,
.slick-next.active,
.slick-prev:active,
.slick-next:active {
  background-color: #ede9f1;
}
.slick-prev:before,
.slick-next:before {
  font-family: HEVA;
  font-size: 35px;
  font-size: 2.1875rem;
  line-height: 1;
  color: #00f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -42px;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -42px;
}
.slick-prev:before {
  content: "\e901";
}
[dir="rtl"] .slick-prev:before {
  content: "\e902";
}
.slick-next {
  right: -45px;
}
[dir="rtl"] .slick-next {
  left: -42px;
  right: auto;
}
.slick-next:before {
  content: "\e902";
}
[dir="rtl"] .slick-next:before {
  content: "\e901";
}
@media (max-width: 960px) {
  .carousel-container {
    background-color: #f4f2f7;
  }
  .carousel-container .carousel-title {
    flex-direction: column;
  }
  .carousel-container .carousel-title h3 {
    width: 100%;
    margin-left: 0;
    margin-bottom: 12px;
  }
  .carousel-container .carousel-title .external-links {
    display: flex;
    width: 100%;
    text-align: center;
  }
  .carousel-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}
