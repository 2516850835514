.contact-infos {
  padding-top: 16px;
  padding-bottom: 16px;
}
.contact-infos address {
  font-style: normal;
}
.contact-infos .contact-infos-block {
  display: flex;
  align-items: center;
  background-color: rgba(43,29,70,0.3);
  font-size: 14px;
  font-size: 0.875rem;
  min-height: 50px;
}
.contact-infos .contact-infos-block:first-child {
  margin-top: 22px;
}
.contact-infos .contact-infos-block .icon {
  display: flex;
  align-items: center;
  align-self: stretch;
  text-align: center;
  padding: 5px 10px;
  height: auto;
  font-family: HEVA;
  font-size: 29px;
  font-size: 1.8125rem;
  background: #2b1d46;
}
.contact-infos .contact-infos-block:not(:last-child) {
  border-bottom: 1px solid rgba(255,255,255,0.24);
}
.contact-infos .contact-infos-block > * {
  padding: 13px 19px;
}
@media (max-width: 960px) {
  .contact-infos .contact-infos {
    padding-top: 16px;
  }
  .contact-infos .contact-infos-block:before {
    color: #fff;
  }
}
