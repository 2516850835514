html {
  line-height: 1.15;
/* 1 */
  -webkit-text-size-adjust: 100%;
/* 2 */
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
/* 1 */
  height: 0;
/* 1 */
  overflow: visible;
/* 2 */
}
pre {
  font-family: monospace, monospace;
/* 1 */
  font-size: 1em;
/* 2 */
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
/* 1 */
  text-decoration: underline;
/* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
/* 2 */
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
/* 1 */
  font-size: 1em;
/* 2 */
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
/* 1 */
  font-size: 100%;
/* 1 */
  line-height: 1.15;
/* 1 */
  margin: 0;
/* 2 */
}
button,
input {
/* 1 */
  overflow: visible;
}
button,
select {
/* 1 */
  text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
/* 1 */
  color: inherit;
/* 2 */
  display: table;
/* 1 */
  max-width: 100%;
/* 1 */
  padding: 0;
/* 3 */
  white-space: normal;
/* 1 */
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
/* 1 */
  padding: 0;
/* 2 */
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
/* 1 */
  outline-offset: -2px;
/* 2 */
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
/* 1 */
  font: inherit;
/* 2 */
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
button {
  background: none;
  border: 0;
  color: inherit;
/* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button /* for input */;
  -webkit-user-select: none /* for button */;
  -moz-user-select: none;
  -ms-user-select: none;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.huik-icon-Download:before {
  content: "\e90d";
}
.huik-icon-Edit:before {
  content: "\e90e";
}
.huik-icon-Facebook:before {
  content: "\e90f";
}
.huik-icon-Info:before {
  content: "\e910";
}
.huik-icon-Youtube:before {
  content: "\e911";
}
.huik-icon-Arrow-Down:before {
  content: "\e900";
}
.huik-icon-Arrow-Left:before {
  content: "\e901";
}
.huik-icon-Arrow-Right:before {
  content: "\e902";
}
.huik-icon-Arrow-Up:before {
  content: "\e903";
}
.huik-icon-Attachment:before {
  content: "\e904";
}
.huik-icon-Burger-OFF:before {
  content: "\e905";
}
.huik-icon-Burger-ON:before {
  content: "\e906";
}
.huik-icon-Linkedin:before {
  content: "\e907";
}
.huik-icon-mail:before {
  content: "\e908";
}
.huik-icon-Phone:before {
  content: "\e909";
}
.huik-icon-Pin:before {
  content: "\e90a";
}
.huik-icon-Twitter:before {
  content: "\e90b";
}
.huik-icon-Website:before {
  content: "\e90c";
}
#rgpd-banner #rgpd-banner-inner {
  max-width: 1200px;
  padding: 0 45px;
}
#rgpd-banner #rgpd-banner-content-title {
  position: relative;
}
#rgpd-banner #rgpd-banner-content-title:before {
  position: absolute;
  left: 0;
  top: 50%;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
  font-size: 2rem;
  content: "\e910";
  font-weight: normal;
  font-family: HEVA;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
}
#rgpd-banner #rgpd-banner-content-title,
#rgpd-banner #rgpd-banner-content-text {
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
  font-weight: 600;
  line-height: 1.33;
  margin: 1.5em 0;
}
#rgpd-banner #rgpd-banner-buttons {
  flex-direction: column;
}
#rgpd-banner #rgpd-banner-buttons button {
  margin: 4px;
}
#rgpd-banner #rgpd-banner-buttons-reject {
  border-radius: 4px;
  padding: 6px 12px;
  min-width: 110px;
  color: #fff;
  font-weight: 600;
  transition: background-color ease 150ms;
  border: solid 1px;
  color: ;
  background-color: ;
  transition: background ease 150ms;
}
#rgpd-banner #rgpd-banner-buttons-reject.hover,
#rgpd-banner #rgpd-banner-buttons-reject:hover {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-reject.hover + .huik-icon-container,
#rgpd-banner #rgpd-banner-buttons-reject:hover + .huik-icon-container {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-reject.active,
#rgpd-banner #rgpd-banner-buttons-reject:active {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-reject.active + .huik-icon-container,
#rgpd-banner #rgpd-banner-buttons-reject:active + .huik-icon-container {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-reject.disabled,
#rgpd-banner #rgpd-banner-buttons-reject[disabled] {
  cursor: default;
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-reject.disabled + .huik-icon-container,
#rgpd-banner #rgpd-banner-buttons-reject[disabled] + .huik-icon-container {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-consent {
  border-radius: 4px;
  padding: 6px 12px;
  min-width: 110px;
  color: #fff;
  font-weight: 600;
  transition: background-color ease 150ms;
  border: solid 1px;
  color: ;
  background-color: ;
  transition: background ease 150ms;
}
#rgpd-banner #rgpd-banner-buttons-consent.hover,
#rgpd-banner #rgpd-banner-buttons-consent:hover {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-consent.hover + .huik-icon-container,
#rgpd-banner #rgpd-banner-buttons-consent:hover + .huik-icon-container {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-consent.active,
#rgpd-banner #rgpd-banner-buttons-consent:active {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-consent.active + .huik-icon-container,
#rgpd-banner #rgpd-banner-buttons-consent:active + .huik-icon-container {
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-consent.disabled,
#rgpd-banner #rgpd-banner-buttons-consent[disabled] {
  cursor: default;
  color: ;
  border-color: ;
  background-color: ;
}
#rgpd-banner #rgpd-banner-buttons-consent.disabled + .huik-icon-container,
#rgpd-banner #rgpd-banner-buttons-consent[disabled] + .huik-icon-container {
  color: ;
  border-color: ;
  background-color: ;
}
body {
  font-family: 'Barlow', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2b1d46;
}
a {
  color: #fff;
}
a:hover {
  color: rgba(255,255,255,0.8);
}
a.noStyle {
  color: inherit;
}
a.noStyle:hover {
  color: inherit;
}
[class*="huik-text-title-"] {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  line-height: 1.4;
}
.huik-text-title-1 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.19;
}
.huik-text-title-2 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.6;
}
.huik-text-title-3 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.2;
}
.huik-text-title-4 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.44;
}
.huik-text-title-5 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6;
}
.huik-text-title-6 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
}
.huik-text-intro,
.huik-text-intro blockquote,
.huik-text-content blockquote {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.4;
  font-style: italic;
}
.huik-text-small {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 600;
}
.huik-text-light {
  color: #6c5d89;
}
.text-center {
  text-align: center;
}
.huik-text-content > * {
  margin-top: 16px;
  margin-bottom: 16px;
}
.huik-text-content > *:first-child {
  margin-top: 0;
}
.huik-text-content > *:last-child {
  margin-bottom: 16px;
}
.huik-text-content ul,
.huik-text-content ol {
  margin-left: 16px;
  margin-right: 16px;
}
.huik-text-content h1,
.huik-text-content h2,
.huik-text-content h3,
.huik-text-content h4,
.huik-text-content h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.huik-text-content h1 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.19;
  margin-top: 12px;
  margin-bottom: 16px;
}
.huik-text-content h2 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.6;
  color: #5e5a85;
  margin-top: 16px;
  margin-bottom: 52px;
}
.huik-text-content h3 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 64px;
  margin-bottom: 16px;
}
.huik-text-content h4 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.44;
  margin-top: 32px;
  margin-bottom: 16px;
}
.huik-text-content h5 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #5e5a85;
  margin-top: 16px;
  margin-bottom: 16px;
}
.huik-text-content h6 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.69;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
}
.huik-text-content pre {
  font-size: 14px;
  font-size: 0.875rem;
  color: #6c5d89;
  background-color: #f4f2f7;
  border-left-style: solid;
  border-width: 4px;
  border-color: #c9c8d6;
  padding: 16px 16px 16px 20px;
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
}
.huik-text-content a {
  color: #00f;
}
.huik-text-content a:hover {
  color: #2221d3;
}
.huik-text-content blockquote p {
  margin-bottom: 0;
  font-size: 20px;
  font-size: 1.25rem;
  font-style: italic;
  line-height: 28px;
  line-height: 1.75rem;
  color: #6c5d89;
}
.huik-text-content img {
  width: 100%;
}
.huik-text-content img.left,
.huik-text-content img.right {
  max-width: 50%;
}
.huik-text-content img.left {
  float: left;
  margin: 0 32px 16px 0;
}
.huik-text-content img.right {
  float: right;
  margin: 0 0 16px 32px;
}
.huik-text-content table {
  width: 100%;
  border: none;
}
.huik-text-content table tr {
  border-bottom: solid #c9c8d6 1px;
}
.huik-text-content table td,
.huik-text-content table th {
  padding: 12px 6px;
  text-align: center;
  vertical-align: middle;
}
.huik-text-content table td:first-child,
.huik-text-content table th:first-child {
  text-align: left;
}
.huik-text-content table thead tr {
  border-bottom: solid #2b1d46 2px;
}
.huik-text-content table thead td,
.huik-text-content table thead th {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.69;
  font-weight: 600;
}
.huik-text-content table tbody th {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  font-size: 1rem;
  color: #6c5d89;
}
.huik-text-content hr {
  margin-top: 32px;
  margin-bottom: 32px;
  box-sizing: border-box;
  height: 0;
  border: 0;
  border-bottom: 2px dotted rgba(201,200,214,0.4);
}
.huik-text-content hr + h1,
.huik-text-content hr + h2,
.huik-text-content hr + h3,
.huik-text-content hr + h4,
.huik-text-content hr + h5,
.huik-text-content hr + h6 {
  margin-top: 0;
}
.huik-text-content .mobile-table-head {
  padding: 12px 6px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  font-size: 1rem;
  color: #6c5d89;
  border-bottom: solid #c9c8d6 1px;
}
.huik-text-content .mobile-table-label {
  padding: 12px 6px 0 6px;
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.69;
  font-weight: 600;
}
.huik-text-content .mobile-table-cell {
  padding: 0 6px 12px 6px;
  text-align: center;
  border-bottom: solid #c9c8d6 1px;
}
.huik-text-content sup {
  vertical-align: super;
  font-size: 10px;
  font-size: 0.625rem;
}
.huik-text-content:after {
  content: '';
  clear: both;
}
.huik-text-content [data-module] {
  display: none;
}
.huik-icon-Download:before {
  content: "\e90d";
}
.huik-icon-Edit:before {
  content: "\e90e";
}
.huik-icon-Facebook:before {
  content: "\e90f";
}
.huik-icon-Info:before {
  content: "\e910";
}
.huik-icon-Youtube:before {
  content: "\e911";
}
.huik-icon-Arrow-Down:before {
  content: "\e900";
}
.huik-icon-Arrow-Left:before {
  content: "\e901";
}
.huik-icon-Arrow-Right:before {
  content: "\e902";
}
.huik-icon-Arrow-Up:before {
  content: "\e903";
}
.huik-icon-Attachment:before {
  content: "\e904";
}
.huik-icon-Burger-OFF:before {
  content: "\e905";
}
.huik-icon-Burger-ON:before {
  content: "\e906";
}
.huik-icon-Linkedin:before {
  content: "\e907";
}
.huik-icon-mail:before {
  content: "\e908";
}
.huik-icon-Phone:before {
  content: "\e909";
}
.huik-icon-Pin:before {
  content: "\e90a";
}
.huik-icon-Twitter:before {
  content: "\e90b";
}
.huik-icon-Website:before {
  content: "\e90c";
}
*,
*::after,
*::before {
  outline: 0;
  box-sizing: border-box;
}
* {
  outline: 0;
  box-sizing: border-box;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
body.home,
body.teams,
body.prestations,
body.solutions {
  background-attachment: fixed;
  background-image: linear-gradient(to right, #fff, #fff 32px, transparent 32px, transparent), linear-gradient(to left, #fff, #fff 32px, transparent 32px, transparent), linear-gradient(60deg, rgba(255,107,137,0.2), rgba(255,102,96,0)), linear-gradient(to bottom, #2b1d46, #2b1d46);
}
body.voeux {
  background-color: #2b1d46;
}
body.voeux .header {
  display: none;
}
body.voeux .sticky-header-spacer {
  display: none;
}
body.voeux .cadre-container {
  padding-left: 0;
  padding-right: 0;
}
a {
  text-decoration: none;
}
.hidden {
  display: none;
}
.cadre-container {
  padding-left: 32px;
  padding-right: 32px;
}
.center-container {
  margin-left: auto;
  margin-right: auto;
  width: 72%;
  min-width: 715px;
  max-width: 991px;
}
.static-page .intro {
  width: 72%;
  margin-bottom: 32px;
}
@media (max-width: 960px) {
  #rgpd-banner #rgpd-banner-inner {
    flex-direction: column;
  }
  body {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.75;
  }
  .huik-text-title-1 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.45;
  }
  .huik-text-title-2 {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.45;
  }
  .huik-text-title-3 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.4;
  }
  .huik-text-title-4 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.45;
  }
  .huik-text-intro,
  .huik-text-intro blockquote,
  .huik-text-content blockquote {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
  }
  .huik-text-small {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.4;
  }
  .huik-text-intro h1,
  .huik-text-content h1,
  .huik-text-intro blockquote h1,
  .huik-text-content blockquote h1,
  .huik-text-intro h2,
  .huik-text-content h2,
  .huik-text-intro blockquote h2,
  .huik-text-content blockquote h2,
  .huik-text-intro h3,
  .huik-text-content h3,
  .huik-text-intro blockquote h3,
  .huik-text-content blockquote h3 {
    line-height: 1.45;
  }
  .huik-text-intro h4,
  .huik-text-content h4,
  .huik-text-intro blockquote h4,
  .huik-text-content blockquote h4 {
    line-height: 1.38;
  }
  .huik-text-intro h5,
  .huik-text-content h5,
  .huik-text-intro blockquote h5,
  .huik-text-content blockquote h5 {
    line-height: 1.38;
  }
  .huik-text-intro h6,
  .huik-text-content h6,
  .huik-text-intro blockquote h6,
  .huik-text-content blockquote h6 {
    line-height: 1.45;
  }
  .huik-text-intro div,
  .huik-text-content div,
  .huik-text-intro blockquote div,
  .huik-text-content blockquote div {
    max-width: 100%;
    font-size: 16px;
    font-size: 1rem;
  }
  .huik-text-intro blockquote,
  .huik-text-content blockquote,
  .huik-text-intro blockquote blockquote,
  .huik-text-content blockquote blockquote {
    max-width: 100%;
    float: none;
    padding: 0;
    margin: 32px;
  }
  .huik-text-intro pre,
  .huik-text-content pre,
  .huik-text-intro blockquote pre,
  .huik-text-content blockquote pre {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .huik-text-intro table,
  .huik-text-content table,
  .huik-text-intro blockquote table,
  .huik-text-content blockquote table,
  .huik-text-intro thead,
  .huik-text-content thead,
  .huik-text-intro blockquote thead,
  .huik-text-content blockquote thead,
  .huik-text-intro tbody,
  .huik-text-content tbody,
  .huik-text-intro blockquote tbody,
  .huik-text-content blockquote tbody,
  .huik-text-intro th,
  .huik-text-content th,
  .huik-text-intro blockquote th,
  .huik-text-content blockquote th,
  .huik-text-intro td,
  .huik-text-content td,
  .huik-text-intro blockquote td,
  .huik-text-content blockquote td,
  .huik-text-intro tr,
  .huik-text-content tr,
  .huik-text-intro blockquote tr,
  .huik-text-content blockquote tr {
    display: block;
  }
  .huik-hidden-medium-down {
    display: none !important;
  }
  body.home,
  body.teams,
  body.prestations,
  body.solutions {
    background-attachment: fixed;
    background-image: linear-gradient(to right, #fff, #fff 0, transparent 0, transparent), linear-gradient(to left, #fff, #fff 0, transparent 0, transparent), linear-gradient(60deg, rgba(255,107,137,0.2), rgba(255,102,96,0)), linear-gradient(to bottom, #2b1d46, #2b1d46);
  }
  .cadre-container {
    padding-left: 0;
    padding-right: 0;
  }
  .center-container {
    width: 100%;
    min-width: auto;
    max-width: 100%;
  }
}
@media (max-width: 600px) {
  .huik-hidden-small-down {
    display: none !important;
  }
}
@media (min-width: 601px) {
  .huik-hidden-small-up {
    display: none !important;
  }
}
@media (min-width: 961px) {
  .huik-hidden-medium-up {
    display: none !important;
  }
}
@media (max-width: 1280px) {
  .huik-hidden-large-down {
    display: none !important;
  }
}
@media (min-width: 1281px) {
  .huik-hidden-large-up {
    display: none !important;
  }
}
