.grecaptcha-badge {
  visibility: hidden;
}
.form-container {
  display: flex;
  flex-direction: column;
}
.form-container .actions {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.form-container .actions .actions-message {
  flex: 1;
  margin-right: 12px;
}
.form-container .actions button[type="submit"] {
  margin-left: auto;
}
.form-container .actions .common-error-message {
  color: #ff3c48;
}
.form-container .input-group-container {
  display: flex;
  align-items: center;
}
.form-container .input-group-container .input-container {
  flex: 1;
}
.form-container .huik-input-container input,
.form-container .huik-input-container .input-file-element {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 12px;
  line-height: 0.75rem;
  padding: 8px 8.8px;
}
.form-container .huik-input-container.error .huik-input-footer .huik-input-message-lib {
  color: #ff3c48;
}
.form-container .huik-input-header {
  padding: 10px 0;
}
.form-container .huik-input-footer {
  min-height: 16px;
}
.form-container .recaptcha-disclaimer {
  display: block;
  margin: 0 8px 8px 8px;
}
.form-container .recaptcha-disclaimer a {
  opacity: 0.5;
}
.form-container .recaptcha-disclaimer a:hover {
  opacity: 0.7;
}
@media (max-width: 960px) {
  .form-container .input-group-container {
    display: block;
  }
  .form-container .huik-input-container input,
  .form-container .huik-input-container textarea {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.3;
  }
}
